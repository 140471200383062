import React from "react"
import { graphql } from "gatsby"

import PageWrapper from "../components/page-wrapper"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Text from "../components/text"

export const query = graphql`
  query Credits {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default () => {
  return (
    <PageWrapper name="credits">
      <SEO title="Credits" />
      <Grid>
        <Grid.Item
          gridColumn={"text-start / text-end"}
          minHeight={["calc(100vh - 25.2rem)", "calc(100vh - 33.6rem)"]}
          pt={["y.mobile.2.0", "y.desktop.2.0"]}
        >
          <Text.H3>Copyright {new Date().getFullYear()}</Text.H3>
          <Text.P>
            Content: Laurin Gausch
            <br />
            Design and Code:{" "}
            <Text.A href="https://4com.at" target="blank" activeIndicator>
              Florian Nebenführ
            </Text.A>
          </Text.P>
        </Grid.Item>
      </Grid>
    </PageWrapper>
  )
}
